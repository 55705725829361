import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import './HomeView.scss';
import { replace } from 'lodash';

class HomeView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: ''
        }
    }
    componentDidMount() {
        window.qstring = window.location.search;
        const qs = new URLSearchParams(window.location.search);

        if (qs.has("shipId") && qs.has("alertId") && qs.has("token")) {
            let alertType = "AAQS";
            if (qs.has("alertSubType")) {
                switch (qs.get("alertSubType").replace(/\s/g, "").toLowerCase()) {
                    case "slowturning":
                        alertType = "ENGINE";
                        break;
                }

            }
            this.props.history.push(`/live/ship/alertDetail/?shipId=${qs.get("shipId")}&alertType=${alertType}&alertId=${qs.get("alertId")}&token=${qs.get("token")}`)
        }
        else
            this.setState({ token: qs.get("token") }, this.props.fetchUserData);

                //this.setState({ shipId: qs.get("shipId"), alertId: qs.get("alertId"), alertSubType: subType, token: qs.get("token") });
    }

    render() {
        const { user, isLiveEnable, isMyShipEnable, myShipUrl, isOnBoard, shipId } = this.props;

        if (!user || user.length === 0)
            return null;

        const liveLink = '/live/' + (isOnBoard && shipId.length > 0 ? `ship/?shipId=${shipId}&` : `?`) + `token=${this.state.token}`;

        return (
            <div className="pageContent">
                <div className="content">
                    <div>
                        <ul className="menu">
                            {isLiveEnable ? <Link to={liveLink}>
                                <li className="menuItem"><i className="icon icon-live"></i><span className="menuText">Live</span><i className="icon-right icon-next"></i></li>
                            </Link> : null}
                            {isMyShipEnable ? <li className="menuItem" onClick={() => window.location.href = `${myShipUrl}${this.state.token}`}><i className="icon icon-my_ship"></i><span className="menuText">MyShip</span><i className="icon-right icon-next"></i></li> : null}
                            
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(HomeView);